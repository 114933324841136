#Contact {
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  transform: translate(0, -8%);
  gap: 2.5rem;
  padding: 0 var(--margin);
  height: calc(100vh - var(--navigation-height));
}

#Contact > .description {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.description > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: fit-content;
  column-gap: 5em;
  row-gap: .5em;
}

.description > div > label {
  grid-column: 1 / -1;
  font-size: var(--fs-compact);
  text-transform: uppercase;
  font-size: var(--fs-compact);
  letter-spacing: 0.75em;
}

.description > div > a:hover {
  text-shadow: 0.15em 0.15em 0 var(--quaternary-clr);
  /* animation: jiggle 0.25s ease-in-out; */
}

.description > div > a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  font-size: var(--fs-big);
  font-weight: 800;
  gap: 1em;
  width: fit-content;
  color: var(--secondary-clr);
}

.description > div > a > svg, 
.description > div > a > img {
  height: 1em !important;
  width: fit-content;
  margin-left: auto;
}

.description > div > a > svg > path {
  stroke: var(--secondary-clr) !important;
}

@media screen and (max-width: 768px) {
  #Contact {
    height: calc(100vh - var(--navigation-height) * 4);
    padding-top: calc(var(--navigation-height) / 1.5);
    gap: 0;
  }

  .description > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
}