#Home {
  position: relative;
  transform: translate(0, -8%);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  padding: 0 var(--margin);
  height: calc(100vh - var(--navigation-height));
}

.title-name {
  /* width: 100%; */
  grid-column: 1 / -1;
  font-size: var(--fs-hero-title);
  font-weight: var(--fw-bold);
  text-transform: uppercase;
  /* animation: animate-name 10s ease-in-out infinite; */
  width: fit-content;
  white-space: nowrap;
}

.stop-title{
  animation: stop-animating-name 10s ease-in-out infinite;
}

@keyframes animate-name {
  from {
    width: fit-content;
    transform: translate(0);
    text-shadow: -0.1em 0.1em var(--quaternary-clr);
  }
  50% {
    width: fit-content;
    transform: translate(calc(100vw - var(--margin) * 2 - 100%));
    text-shadow: 0.1em 0.1em var(--quaternary-clr);
  }
  to {
    width: fit-content;
    transform: translate(0);
    text-shadow: -0.1em 0.1em var(--quaternary-clr);
  }
}

@keyframes stop-animating-name {
  from {
    text-shadow: -0.1em 0.1em var(--quaternary-clr);
  }
  50% {
    text-shadow: 0.1em 0.1em var(--quaternary-clr);
  }
  to {
    text-shadow: -0.1em 0.1em var(--quaternary-clr);
  }
}

#Home > .description {
  grid-column: 1 / 3;
  height: max-content;
  font-size: var(--fs-medium);
  font-weight: var(--fw-light);
}

.assets {
  position: relative;
  grid-row: 3;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: inherit;
  gap: inherit;
}

.assets > * {
  object-fit: cover;
  transform-origin: center;
  overflow: hidden;
  opacity: 0;
}

.hero-start {
  grid-column: 1 / 3;
  height: 40vh;
  width: 100%;
  object-position: 0 100%;
  /* animation: animate-entry-hero-start 0.25s 0.25s linear forwards,
    animate-hero-start 10s ease-in-out 0.5s alternate-reverse infinite; */
  overflow: hidden;
}

@keyframes animate-hero-start {
  from {
    transform: translate(0, 0);
    object-position: 50% 70%;
  }
  to {
    transform: translate(0, 5%);
    object-position: 50% 100%;
  }
}

@keyframes animate-entry-hero-start{
  from{
    height: 0;
  }
  to{
    height: 40vh;
  }
}

.hero-middle {
  position: absolute;
  top: -6%;
  left: -30%;
  grid-column: 3 / 4;
  width: 90%;
  height: 45vh;
  filter: saturate(0);
  object-position: 0 70%;
  /* animation: animate-entry-hero-middle 0.25s 0.25s linear forwards,
    animate-hero-middle 10s ease-in-out 0.5s alternate-reverse infinite; */
  overflow: hidden;
}

@keyframes animate-hero-middle {
  from {
    transform: scale(1);
    object-position: 0% 70%;
  }
  to {
    transform: scale(1.1);
    object-position: 70%;
  }
}

@keyframes animate-entry-hero-middle {
  from{
    transform: translate(0, 100vh);
  }
  to{
    transform: translate(0);
  }
}

.hero-end {
  position: absolute;
  right: 0;
  top: -20%;
  grid-row: 1;
  grid-column: 3/6;
  width: 100%;
  height: 55vh;
  z-index: -1;
  object-position: 10% 70%;
  /* animation: animate-entry-hero-end 0.25s 0.25s linear forwards,
    animate-hero-end 10s ease-in-out 0.5s alternate-reverse infinite; */
  overflow: hidden;
}

@keyframes animate-hero-end {
  from {
    transform: translate(0);
    object-position: 50% 70%;
  }
  to {
    transform: translate(-2.5%, 0);
    object-position: 50% 80%;
  }
}

@keyframes animate-entry-hero-end {
  from {
    width: 0;
  }
  to {
    width: 100%
  }
}

.specific-page-link {
  padding-top: 1rem;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-row: 4;
  gap: 0.5em;
}

.specific-page-link > img,
.specific-page-link > svg {
  width: fit-content;
  height: 1em;
  float: left;
}

.specific-page-link > span {
  font-size: var(--fs-regular);
  text-decoration: none;
  box-shadow: inset -1em 0 0 -0.25em var(--primary-clr),
    inset 0 -1.5px 0 -1px var(--secondary-clr);
  text-transform: uppercase;
  letter-spacing: 0.75em;
}

.specific-page-link > span:hover {
  animation: animate-link-underline-loop 1s linear infinite forwards;
}

@keyframes animate-link-underline-loop {
  100% {
    box-shadow: inset -1em 0 0 -0.25em var(--primary-clr),
      inset 0 -1.5px 0 -1px var(--secondary-clr);
  }
  50% {
    box-shadow: inset -11em 0 0 -0.25em var(--primary-clr),
      inset 0 -1.5px 0 -1px var(--secondary-clr);
  }
  51% {
    box-shadow: inset 11em 0 0 -0.25em var(--primary-clr),
      inset 0 -1.5px 0 -1px var(--secondary-clr);
  }
  100% {
    box-shadow: inset -1em 0 0 -0.25em var(--primary-clr),
      inset 0 -1.5px 0 -1px var(--secondary-clr);
  }
}

@media screen and (max-width: 858px) {
  .hero-middle {
    width: auto;
    height: 45vh;
  }
}

@media screen and (max-width: 768px) {
  #Home {
    padding-top: calc(var(--navigation-height) / 1.5);
    gap: 0;
  }
  .hero-middle {
    height: 45vh;
  }
}

@media screen and (max-width: 500px) {
  #Home {
    padding-top: calc(var(--navigation-height) / 1.5);
    gap: 0;
  }

  #Home > .description {
    grid-column: 1 / 2;
  }

  .hero-start {
    grid-column: 3/-1;
    transform: translate(0, 30%);
    height: 35vh;
    margin-top: 3em;
  }
  .hero-middle {
    grid-column: 4/-1;
    transform: translate(50%, -40%);
    height: 30vh;
    margin-top: 1em;
  }
  .hero-end {
    grid-column: 1/4;
    height: 35vh;
    margin-top: 1em;
  }
}
