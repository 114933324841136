#NavigationPanel {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - var(--margin) * 2);
  width: calc(100vw - var(--margin) * 2);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 0 var(--margin);
  padding-top: var(--navigation-height);
  background-color: var(--secondary-clr);
  overflow: hidden;
  z-index: 1;
  place-items: center;
  transform-origin: center;
  transform: translate(100%);
}

@keyframes nav-page-animate-entry {
  from {
    transform: translate(100vw);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes nav-page-animate-end {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(100vw);
  }
}

.pageLink {
  width: 100%;
  text-align: end;
  text-transform: uppercase;
  text-decoration: none;

  font-size: calc(var(--fs-hero-title) - 100%);
  font-weight: var(--fw-bold);

  color: var(--secondary-clr);
  text-shadow: -1px -1px 0 var(--quaternary-clr),
    -1px 1px 0 var(--quaternary-clr), 1px -1px 0 var(--quaternary-clr),
    1px 1px 0 var(--quaternary-clr);
  transition: 0.5s ease;
  
  overflow: hidden;
  /* animation: nav-link-animate 0.5s 0.5s linear forwards; */
}

@keyframes nav-link-animate {
  from{
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.pageLink:hover,
.pageLink:focus {
  color: var(--quaternary-clr);
  text-shadow: 0.1em 0.1em 0 var(--ternary-clr);
}

.links {
  grid-column: -1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

@media screen and (max-width: 768px) {
  #NavigationPanel {
    place-items: start end;
  }
  .links {
    margin-top: 10vh;
  }
}
