#Work {
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* grid-template-rows: repeat(3, 1fr); */
  gap: 1rem;
  transform: translate(0, -3%);
  padding: 0 var(--margin);
}

#Work > .description {
  grid-column: 1 /-1;
  font-size: var(--fs-medium);
  font-weight: var(--fw-light);
}

.projects {
  grid-row: 3;
  grid-column: 1 /-1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.project{
  position: relative;
  white-space: nowrap;
  width: min-content;
}

.project-title,
.project-tecs {
  max-width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: var(--fw-bold);
  text-decoration: none;
  text-transform: uppercase;
  gap: 0.5em;
  text-size-adjust: none;
}

.project-title > svg,
.project-tecs > svg {
  height: 1em;
  width: fit-content;
}

.project-title {
  font-size: var(--fs-title);
}

.project-title:hover,
.project-tecs:hover {
  text-shadow: 0.15em 0.15em #ffffff;
  /* animation: jiggle 0.25s ease-in-out; */
}

@keyframes jiggle {
  0% {
    transform: translate(0%);
  }
  20% {
    transform: translate(-5%);
  }
  40% {
    transform: translate(5%);
  }
  60% {
    transform: translate(-2.5%);
  }
  80% {
    transform: translate(2.5%);
  }
  90% {
    transform: translate(-1.25%);
  }
  95% {
    transform: translate(1.25%);
  }
  97.5% {
    transform: translate(-0.65%);
  }
  100% {
    transform: translate(0);
  }
}

.project-tecs {
  font-size: var(--fs-regular);
  color: var(--ternary-clr);
}

.project-tecs > svg > g > path {
  color: var(--ternary-clr);
}

@media screen and (max-width: 858px) {
  #Work {
    transform: translate(0);
  }
}

.project-snapshot{
  position: absolute;
  height: 0;
  top: 0;
  left: 100%;
  border: 1px solid black;
  transform-origin: center;
  transform: rotate(0) scale(0);
  opacity: 0;
  transition: 0.5s ease;
}

.project:hover > .project-snapshot{
  transform: rotate(360deg) scale(1);
  height: 100%;
  opacity: 1;
}