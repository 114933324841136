@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;900&display=swap');

:root {
  --font-family: "Montserrat", sans-serif;

  --margin: 50px;

  --fs-small: 0.6vmax;
  --fs-compact: 0.8vmax;
  --fs-regular: 1.1vmax;
  --fs-medium: 1.75vmax;
  --fs-big: 2.4vmax;
  --fs-large: 3.15vmax;
  --fs-title: 4.2vmax;
  --fs-hero-title: 10vmax;

  --fw-light: 300;
  --fw-normal: 400;
  --fw-medium: 600;
  --fw-bold: 900;

  --primary-clr: #e2e2e5;
  --secondary-clr: #1b1b1b;
  --ternary-clr: #868687;
  --quaternary-clr: #ffffff;

  --navigation-height: 15vh;
}

@media screen and (max-width: 858px) {
  :root {
    --margin: 25px;
    --fs-compact: 0.9vmax;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --margin: 25px;
    --navigation-height: 10vh;
  }
}

@media screen and (max-width: 425px) {
  :root {
    --margin: 10px;
    /* --fs-hero-title : 9vmax; */
  }
}

@media screen and (max-width: 320px) {
  :root {
    --margin: 10px;
  }
}
